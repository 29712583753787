import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import about_image from "../../assets/png/about_img.png";
import about_icon from "../../assets/svg/about_icon.svg";
import border_right_icon from "../../assets/png/Border_right_icon.svg";
import WebHeader from "../common/WebHeader";

const Bitutrading = () => {
  const [activeTab, setActiveTab] = useState("history");
  return (
    <div className="  d-flex flex-column">
      <div>
        <WebHeader />
      </div>
      <Container className="py-5">
        <div>
          <h1 className="mt-3 fw-bold color_orange heebo">ABOUT US</h1>
          <p className="fs_16 mt-4">
            BituConnect is a cutting-edge platform created by professionals from
            the bitumen industry who have a deep understanding of the market’s
            needs. Operated by Bitutrading FZ-LLC, BituConnect was designed to
            streamline the bitumen procurement process, making it easier for
            buyers and vendors to connect and transact seamlessly.
          </p>
          <p className="fs_16 mt-4">
            As we move into a digital and tech-driven era, simplicity is at the
            heart of everything we do. Our platform offers an intuitive,
            user-friendly experience that simplifies the complexities of the
            bitumen supply chain. With features like real-time cargo tracking,
            secure document management, and efficient vendor-buyer
            communication, BituConnect ensures transparency and efficiency at
            every stage.
          </p>
          <p className="fs_16 mt-4">
            Our team’s extensive industry expertise ensures that BituConnect is
            not just a tool, but a comprehensive solution designed to save you
            time and optimize your procurement operations.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Bitutrading;
