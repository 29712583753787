import React from "react";
import footer_image from "../../assets/png/footer_image.png";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_bg py-4">
      <Container>
        <div className="d-md-flex align-items-center justify-content-between">
          <p className=" manrope_FF text-white mb-0 ">
            © 2024 All Rights Reserved |{" "}
            <span className="color_orange">Bituconnect.com</span>
          </p>
          <div className="d-flex mt-3 mt-md-0 align-items-center gap-4">
            <Link to="/terms-of-service">
              <p className="mb-0 cusor_pointer text-white">Terms of Service </p>
            </Link>
            <Link to="/privacy-policy">
              <p className="mb-0 cusor_pointer text-white">Privacy Policy</p>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
