import React from "react";
import AboutHero from "../components/aboutpage/AboutHero";
import Bitutrading from "../components/aboutpage/Bitutrading";
import AllOver from "../components/aboutpage/AllOver";
import Question from "../components/aboutpage/Question";
import Testimonials from "../components/aboutpage/Testimonials";
import HomeFooter from "../components/aboutpage/HomeFooter";

const AboutPage = () => {
  return (
    <div>
      {/* <AboutHero /> */}
      <Bitutrading />
      {/* <AllOver />
      <Question />
      <Testimonials />
      <HomeFooter /> */}
    </div>
  );
};

export default AboutPage;
